import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import BaseService from '../helpers/baseServices';
import { global_variables } from '../helpers/globalVariables';


// Create the context
const GeneralContext = createContext<any>(null);

// Create a custom hook for easy access
export const useGeneralContext = () => useContext(GeneralContext);

interface ApiProviderProps {
    children: ReactNode;
}

export const GeneralContextProvider: React.FC<ApiProviderProps> = ({ children }) => {

    const [eventId, setEventId] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [event_data, setEventData] = useState(null)


    //onload
    useEffect(() => {
        if (eventId) {
            const getSingleEventInfo = async () => {
                try {
                    const response = await BaseService.get_api(`${global_variables.get_single_event}/${eventId}`)
                    console.log("fetchEventRes:", response?.data)
                    setEventData(response?.data?.payload)
                    setIsLoading(false)
                } catch (error) {
                    console.log("fetchEventErr:", error)
                }
            }

            getSingleEventInfo()
        }
    }, [eventId])

    return (
        <GeneralContext.Provider value={{ isLoading, setEventId, event_data }}>
            {children}
        </GeneralContext.Provider>
    );
};
