export function GenerateMenuItems(eventObj: any) {
  const menuItems = [];

  if (eventObj.name) {
    menuItems.push("Home");
  }
  if (eventObj.about) {
    menuItems.push("About");
  }
  if (eventObj.speakers) {
    menuItems.push("Speakers");
  }
  if (eventObj.agenda) {
    menuItems.push("Agenda");
  }
  if (eventObj.sponsors) {
    menuItems.push("Sponsors");
  }

  return menuItems;
}
