
import React, { useEffect, useState } from 'react'
import logoImg from "../assets/images/loggoImg.png"
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGeneralContext } from '../context/generalContext';
import { GenerateMenuItems } from '../functions/generateMenuItems';


interface navProps {
    solid?: boolean
    homeActive?: boolean
}


const CustomStickyNav = ({ solid, homeActive }: navProps) => {

    const { id } = useParams()
    const { event_data } = useGeneralContext()
    const navigate = useNavigate()
    const [navbar, setNavbar] = useState(false)
    const [activeMenu, setActiveMenu] = useState(0)

    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    //onload
    useEffect(() => {
        console.log('Component mounted, adding scroll event listener');
        window.addEventListener('scroll', changeBackground);
        return () => {
            console.log('Component unmounted, removing scroll event listener');
            window.removeEventListener('scroll', changeBackground);
        };
    }, []);


    //navMenus
    const navMenus = GenerateMenuItems(event_data)


    return (
        <div className={`${navbar ? 'navbar active' : 'navbar'} fixed w-full z-10 ${solid && 'shadow-sm bg-white'}`}>
            <div>
                <div className="w-full flex justify-between items-center 2xl:px-[300px] xl:px-[150px] lg:px-[100px] md:px-[30px] px-[20px] py-5 z-10">
                    <div>
                        <Link to="/">
                            <img src={logoImg} alt="" />
                        </Link>
                    </div>

                    {/* middle */}
                    <div className="text-[14px] font-[500] 2xl:flex xl:flex lg:flex hidden gap-10 items-center">
                        {
                            navMenus.map((menu, i) => (
                                <Link key={i} to="#" className={`hover:text-defaultGold ${activeMenu === i ? 'border-b-[3px] border-b-defaultPink' : 'border-b-[3px] border-b-transparent'} ${solid ? 'text-black' : 'text-white'}`} onClick={() => setActiveMenu(i)}>
                                    <p className='text-[16px] font-[400]'>{menu}</p>
                                </Link>
                            ))
                        }
                    </div>

                    <div className='flex gap-[16px] items-center'>
                        <button className='h-[40px] px-[24px] rounded-[8px] bg-gradient-to-r from-defaultPink to-defaultOrange text-white' onClick={() => navigate(`/register/${id}`)}>
                            <p className='text-[14px] font-[500]'>Register</p>
                        </button>

                        {/* <button className='h-[40px] px-[24px] rounded-[8px] border-[1px] border-defaultPink text-white'>
                            <p className='text-[14px] font-[500]'>Login</p>
                        </button> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomStickyNav
