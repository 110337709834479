import React, { useEffect } from "react";
import {
    APIProvider,
    Marker,
    Map,
    useMarkerRef
} from "@vis.gl/react-google-maps";
import { useGeneralContext } from '../../context/generalContext';
import { MAPS_KEY } from "../../helpers/constants";



const MapComponent = () => {

    const { event_data } = useGeneralContext()
    const [markerRef, marker] = useMarkerRef();

    useEffect(() => {
        if (!marker) {
            console.error("Marker not initialized yet.");
            return;
        }

        const map = marker.getMap();
        if (map) {
            console.log("Map loaded successfully");
        }
    }, [marker]);


    const markerInfo = {
        lat: event_data?.location?.latitude,
        lng: event_data?.location?.longitude,
    };


    return (
        <>
            <div
                style={{
                    height: "600px",
                }}
            >
                <APIProvider apiKey={MAPS_KEY}>
                    <Map
                        // mapId={"e93373b426b7283"}
                        defaultZoom={15}
                        defaultCenter={{ lat: event_data?.location?.latitude, lng: event_data?.location?.longitude }}
                        style={{ borderRadius: "20px" }}
                    >
                        <Marker ref={markerRef} position={{ lat: event_data?.location?.latitude, lng: event_data?.location?.longitude }} />
                    </Map>
                </APIProvider>
            </div>
        </>
    );
};

export default MapComponent;
