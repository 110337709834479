import React, { useEffect } from 'react'
import CustomStickyNav from '../../components/customStickyNav'
import { HiOutlineCalendarDays, HiOutlineClock } from "react-icons/hi2";
import { IoLocationOutline } from "react-icons/io5";
import CountdownTimer from '../../components/shared/countdownTimer';
import CustomFooter from '../../components/customFooter';
import { useGeneralContext } from '../../context/generalContext';
import { CircleLoader } from 'react-spinners';
import { useNavigate, useParams } from 'react-router-dom';
import { FormatDateRange } from '../../functions/formatDateRange';
import MarqueeSlider from '../../components/shared/marqueeSlider';
import { FormatTargetDate } from '../../functions/formatTargetDate';
import MapComponent from '../../components/shared/mapComponent';


const HomePage = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const { isLoading, setEventId, event_data } = useGeneralContext()


    //onload
    useEffect(() => {
        if (id) {
            setEventId(id)
        }

        // eslint-disable-next-line
    }, [id])


    return (
        <>
            {
                isLoading ?
                    <div className="h-screen w-full overflow-y-hidden">
                        <div className="flex flex-col justify-center items-center h-full">
                            <CircleLoader size={100} color='#F7426F' />
                            <p className='mt-5 font-[400] text-gray-500'>Fetching event details ...</p>
                        </div>
                    </div>
                    :

                    <div className="h-screen w-full">
                        <div className="w-full">
                            <CustomStickyNav homeActive />


                            {/* banner */}
                            <div className="2xl:h-[700px] xl:h-[700px] lg:h-[700px] md:h-[700px] h-[700px] w-full bg-bgVideo relative">
                                <img className='w-full 2xl:h-[700px] xl:h-[700px] lg:h-[700px] md:h-[700px] h-[700px] object-cover' src={event_data?.image?.[0]} alt='' />
                                <div className="2xl:h-[700px] xl:h-[700px] lg:h-[700px] md:h-[700px] h-[700px] w-full absolute bg-black bg-opacity-[80%] bottom-0">
                                    <div className='flex flex-col justify-center items-center text-white 2xl:h-[700px] xl:h-[700px] lg:h-[700px] md:h-[700px] h-[700px] p-5'>

                                        <div className="flex gap-[28px] items-center">
                                            <div className='flex gap-2 items-center'>
                                                <HiOutlineCalendarDays className='h-5 w-5' />
                                                <p className='2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[14px] text-[14px] font-[400]'>
                                                    {FormatDateRange(event_data?.eventStartDateTime, event_data?.eventEndDateTime)}
                                                </p>
                                            </div>
                                            <div className='flex gap-2 items-center'>
                                                <IoLocationOutline className='h-5 w-5' />
                                                <p className='2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[14px] text-[14px] font-[400]'>
                                                    {event_data?.location?.name}, {event_data?.location?.region || event_data?.location?.city}
                                                </p>
                                            </div>
                                        </div>

                                        <p className='2xl:text-[63px] xl:text-[63px] lg:text-[63px] md:text-[42px] text-[32px] font-[900] text-center mt-[17px] 2xl:w-[1200px] xl:w-[1200px] lg:w-[1200px] md:w-[800px] uppercase'>
                                            {event_data?.name}
                                        </p>

                                        <div className="mt-[64px]">
                                            <button className='2xl:w-[400px] xl:w-[400px] lg:w-[400px] md:w-[400px] w-[250px] h-[60px] px-[24px] rounded-[8px] bg-gradient-to-r from-defaultPink to-defaultOrange text-white' onClick={() => navigate(`/register/${id}`)}>
                                                <p className='text-[24px] font-[600]'>Register</p>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="content-container">
                            {/* slider text */}
                            <div className="py-10 bg-[#0C0C0C] text-white marquee-container">
                                <MarqueeSlider name={event_data?.name} />
                            </div>

                            {/* count down and about */}
                            <div className="py-[100px] w-full 2xl:px-[300px] xl:px-[150px] lg:px-[100px] md:px-[30px] px-[20px]">
                                <p className='text-[20px] font-[800] text-center'><span className='text-defaultBlue'>
                                    HURRY UP!</span> DONT WASTE TIME
                                </p>

                                <div className="mt-[22px]">
                                    <CountdownTimer date={FormatTargetDate(event_data?.eventStartDateTime).toString()} />
                                </div>

                                {/* about */}
                                <div className="mt-[100px]">
                                    <p className='text-[40px] font-[700] text-defaultText'>ABOUT THE EVENT</p>

                                    <div className="mt-[48px] 2xl:grid xl:grid lg:grid md:grid grid-cols-2 gap-[44px] text-defaultText">
                                        <div className='w-full'>
                                            <img className='h-[400px w-full object-cover rounded-[10px]' src="https://cdn.pixabay.com/photo/2016/11/23/15/48/audience-1853662_640.jpg" alt="" />
                                        </div>

                                        <div className='w-full mt-10 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0'>
                                            <p className='text-[40px] font-[700]'>Welcome to Innovate Africa 2025</p>

                                            <p className='mt-[16px] font-[400] text-[18px]'>
                                                {event_data?.about}
                                            </p>

                                            {/* <div className="mt-[43px] flex gap-20 items-center">
                                                <div>
                                                    <p className='text-[40px] font-[700]'>80</p>
                                                    <p className='text-[16px] font-[400]'>Agencies Included</p>
                                                </div>
                                                <div>
                                                    <p className='text-[40px] font-[700]'>4.8K</p>
                                                    <p className='text-[16px] font-[400]'>Total Attendee</p>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* speakers */}
                            {/* <div className="py-[100px] w-full 2xl:px-[300px] xl:px-[150px] lg:px-[100px] md:px-[30px] px-[20px] bg-[#0C0C0C]">
                                <p className='text-[40px] font-[700] text-white text-center'>SPEAKERS</p>

                                <div className="mt-[68px] w-full grid grid-cols-4 gap-10">
                                    {
                                        ["", "", "", ""].map((item, i) => (
                                            <div key={i} className='w-full text-[#BDC1CA]'>
                                                <img className='w-full h-[280px] object-cover' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzgcMXgjgzOqFm_ZiFtk07q2ZKiWanW8gPQA&s" alt="" />

                                                <p className='mt-[17px] text-[16px] font-[400]'>Jeff Bezos</p>
                                                <p className='text-[14px] font-[400]'>Lead Speaker</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div> */}

                            {/* agenda */}
                            {/* <div className="py-[100px] w-full 2xl:px-[300px] xl:px-[150px] lg:px-[100px] md:px-[30px] px-[20px]">
                                <p className='text-[40px] font-[700] text-defaultText text-center'>AGENDA</p>

                                <div className="mt-20">
                                    {
                                        ["", "", ""].map((item, i) => (
                                            <div key={i} className="mb-5 border border-defaultText py-5 px-10 grid grid-cols-5 items-center">
                                                <div className='w-full'>
                                                    <img className='h-[162px] w-[162px] object-cover' src="https://www.3iafrica.com/wp-content/uploads/2024/05/11-1.jpg" alt="" />
                                                    <p className='text-[16px] font-[400] mt-3'>Benjamin Nortey</p>
                                                    <p className='text-[14px] font-[400] text-[#565D6D]'>Chairman</p>
                                                </div>

                                                <div className='w-full col-span-3 px-10 border-x border-x-defaultText'>
                                                    <p className='text-[40px] font-[700] text-defaultText'>
                                                        Welcome and introduction to events 2024
                                                    </p>

                                                    <div className="mt-[16px] flex gap-[28px] items-center text-defaultText">
                                                        <div className='flex gap-2 items-center'>
                                                            <HiOutlineClock className='h-5 w-5' />
                                                            <p className='text-[16px] font-[400]'>2:35 PM</p>
                                                        </div>
                                                        <div className='flex gap-2 items-center'>
                                                            <IoLocationOutline className='h-5 w-5' />
                                                            <p className='text-[16px] font-[400]'>Panorama Hotel, CA</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='w-full text-right'>
                                                    <p className='text-[56px] font-[700] text-defaultText'>24</p>
                                                    <p className='text-[14px] font-[400] text-[#565D6D]'>August 2024</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>

                                <div className="mt-[100px]">
                                    <p className='text-[40px] font-[700] text-defaultText'>What to expect...</p>

                                    <div className="mt-[20px] grid grid-cols-3 w-full gap-10">
                                        <div className='w-full'>
                                            <img className='h-[400px] w-full object-cover rounded-[10px]' src="https://www.prosoundweb.com/wp-content/uploads/2020/04/Backstage-Class-Open-PSW.jpg" alt="" />
                                        </div>

                                        <div className='w-full'>
                                            <img className='h-[400px] w-full object-cover rounded-[10px]' src="https://ichef.bbci.co.uk/news/976/cpsprodpb/4FE7/production/_125355402_66a063fa-009f-4265-aee4-e76b55649000.jpg" alt="" />
                                        </div>

                                        <div className='w-full'>
                                            <img className='h-[400px] w-full object-cover rounded-[10px]' src="https://www.shutterstock.com/shutterstock/videos/1057725433/thumb/4.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* map */}
                            <div className="w-full bg-defaultText">
                                <MapComponent />
                            </div>

                            {/* footer */}
                            <CustomFooter />
                        </div>
                    </div>
            }
        </>
    )
}

export default HomePage
