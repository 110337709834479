import React, { useEffect, useRef } from 'react';
import QRCode from 'qrcode';
import { WEBSITE_URL } from '../../helpers/constants';

interface QRCodeGeneratorProps {
    id: string;
}

const QRCodeGenerator: React.FC<QRCodeGeneratorProps> = ({ id }) => {
    const qrCodeCanvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (!id || !qrCodeCanvasRef.current) {
            return;
        }

        // Generate QR code and render it on the canvas
        const generateQRCode = async () => {
            try {
                // URL or data to be encoded in the QR code
                const data = `${WEBSITE_URL}/view-ticket/${id}`;

                // Render the QR code on the canvas
                await QRCode.toCanvas(qrCodeCanvasRef.current, data, {
                    errorCorrectionLevel: 'H', // High error correction level
                    width: 200, // Width of the QR code
                });

                console.log('QR code generated successfully');
            } catch (error) {
                console.error('Error generating QR code:', error);
            }
        };

        generateQRCode();
    }, [id]);

    return (
        <div>
            <canvas ref={qrCodeCanvasRef}></canvas>
        </div>
    );
};

export default QRCodeGenerator;
