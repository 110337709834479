import React from 'react'
// import logoImg from "../assets/images/loggoImg.png"
import { Link } from 'react-router-dom'
import { FaTwitter, FaFacebookF, FaLinkedinIn } from "react-icons/fa";


const CustomFooter = () => {
    return (
        <>
            <div className="w-full p-20 2xl:px-[300px] xl:px-[150px] lg:px-[100px] md:px-[30px] px-[20px]">
                {/* <div className="flex justify-between">
                    <div>
                        <img className='w-[400px]' src={logoImg} alt="" />
                    </div>

                    <div className="flex gap-10 text-defaultText">
                        <div>
                            <p className='text-[16px] font-[600]'>Products</p>

                            <div className="mt-[16px] text-[#57585D] flex flex-col gap-y-2">
                                <Link to='#' className='text-[16px] font-[400]'>
                                    Conference
                                </Link>
                                <Link to='#' className='text-[16px] font-[400]'>
                                    Pricing
                                </Link>
                                <Link to='#' className='text-[16px] font-[400]'>
                                    Solution
                                </Link>
                            </div>
                        </div>

                        <div>
                            <p className='text-[16px] font-[600]'>Company</p>

                            <div className="mt-[16px] text-[#57585D] flex flex-col gap-y-2">
                                <Link to='#' className='text-[16px] font-[400]'>
                                    About
                                </Link>
                                <Link to='#' className='text-[16px] font-[400]'>
                                    Contact
                                </Link>
                                <Link to='#' className='text-[16px] font-[400]'>
                                    Careers
                                </Link>
                            </div>
                        </div>

                        <div>
                            <p className='text-[16px] font-[600]'>Support</p>

                            <div className="mt-[16px] text-[#57585D] flex flex-col gap-y-2">
                                <Link to='#' className='text-[16px] font-[400]'>
                                    Talk to sales
                                </Link>
                                <Link to='#' className='text-[16px] font-[400]'>
                                    Support policy
                                </Link>
                                <Link to='#' className='text-[16px] font-[400]'>
                                    Others
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className='my-10 border-[#CFCFD1]' /> */}

                <div className='2xl:flex xl:flex lg:flex md:flex justify-between w-full text-[#57585D]'>
                    <div className='flex gap-10 items-center'>
                        <p className='2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] text-[14px] font-[400]'>
                            &copy; 2024 Event Inc.
                        </p>

                        <Link to='#' className='2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] text-[14px] font-[400]'>
                            Privacy Policy
                        </Link>
                        <Link to='#' className='2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] text-[14px] font-[400]'>
                            Terms of Service
                        </Link>
                    </div>

                    <div className='flex gap-5 items-center mt-10 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0'>
                        <Link to='#' className='text-[16px] font-[400]'>
                            <FaTwitter className='h-5 w-5' />
                        </Link>
                        <Link to='#' className='text-[16px] font-[400]'>
                            <FaFacebookF className='h-5 w-5' />
                        </Link>
                        <Link to='#' className='text-[16px] font-[400]'>
                            <FaLinkedinIn className='h-5 w-5' />
                        </Link>
                    </div>
                </div>

            </div>
        </>
    )
}

export default CustomFooter
