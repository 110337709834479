export function FormatTargetDate(isoDateString: any) {
  const targetDate: any = new Date(isoDateString);

  const now: any = new Date();
  const diffMs = targetDate - now;

  const diffDays = Math.floor(diffMs / (24 * 60 * 60 * 1000));
  const diffHours = Math.floor(
    (diffMs % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
  );
  const diffMinutes = Math.floor((diffMs % (60 * 60 * 1000)) / (60 * 1000));
  const diffSeconds = Math.floor((diffMs % (60 * 1000)) / 1000);

  return new Date(
    Date.now() +
      diffDays * 24 * 60 * 60 * 1000 +
      diffHours * 60 * 60 * 1000 +
      diffMinutes * 60 * 1000 +
      diffSeconds * 1000
  );
}
