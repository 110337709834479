import React from 'react';

interface theProps {
    name: any
}

const MarqueeSlider = ({ name }: theProps) => {
    return (
        <div className="marquee-container">
            <div className="marquee">
                <p className='font-[400] text-[18px]'>{name}</p>
                <p className='font-[400] text-[18px]'>{name}</p>
                <p className='font-[400] text-[18px]'>{name}</p>
                <p className='font-[400] text-[18px]'>{name}</p>
                <p className='font-[400] text-[18px]'>{name}</p>
                <p className='font-[400] text-[18px]'>{name}</p>
                <p className='font-[400] text-[18px]'>{name}</p>
                <p className='font-[400] text-[18px]'>{name}</p>
            </div>
        </div>
    );
}

export default MarqueeSlider;
