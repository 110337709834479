import React, { useState } from "react";
import { Modal, Form, Input, Spin, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import BaseService from "../../helpers/baseServices";
import { global_variables } from "../../helpers/globalVariables";


interface modalProps {
    isOpened: boolean
    handleClose: () => void
}

const RegisterModal = ({ isOpened, handleClose }: modalProps) => {

    const { id } = useParams()
    const [form] = Form.useForm();
    const [isBusy, setIsBusy] = useState(false)
    const navigate = useNavigate()

    //submit form
    const onFinish = async (values: any) => {
        console.log("formValues:", values)
        let register_data = {
            "email": values?.email,
            "eventId": id,
            "firstName": values?.firstName,
            "lastName": values?.lastName,
            //optional
            "middleName": values?.middleName,
            "companyName": values?.companyName,
        }

        if (!register_data?.middleName) {
            delete register_data?.middleName
        }
        if (!register_data?.companyName) {
            delete register_data?.companyName
        }

        setIsBusy(true)
        try {
            const response = await BaseService.post_api(global_variables.register_event, register_data)
            console.log("registerRes:", response?.data)
            message.success("You have successfully registered for this event.")
            navigate(`/view-ticket/${response?.data?.payload?._id}`)
        } catch (error: any) {
            console.log("registerErr:", error)
            message.error(error?.response?.data?.error || "An error occured, Please try again.")
            setIsBusy(false)
        }
    }

    return (
        <>
            <Modal title="Register for event" open={isOpened} onCancel={handleClose} footer={null} keyboard={false} maskClosable={false}>
                <hr />
                <div className="h-[400px] overflow-y-auto px-3">
                    <Spin spinning={isBusy}>
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            className="mt-3"
                        >
                            <Form.Item
                                label="First Name"
                                name="firstName"
                                rules={[{ required: true, message: "Please input your first name!" }]}
                            >
                                <Input className="h-[40px]" />
                            </Form.Item>
                            <Form.Item
                                label="Last Name"
                                name="lastName"
                                rules={[{ required: true, message: "Please input your last name!" }]}
                            >
                                <Input className="h-[40px]" />
                            </Form.Item>
                            <Form.Item
                                label="Middle Name"
                                name="middleName"
                            >
                                <Input className="h-[40px]" />
                            </Form.Item>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{ required: true, message: "Please input your email!" }]}
                            >
                                <Input className="h-[40px]" />
                            </Form.Item>
                            <Form.Item
                                label="Company Name"
                                name="companyName"
                            >
                                <Input className="h-[40px]" />
                            </Form.Item>

                            <Form.Item>
                                <button className='h-[56px] w-full rounded-[8px] bg-defaultOrange text-white disabled:cursor-not-allowed flex justify-center items-center'>
                                    Register For Event
                                </button>
                            </Form.Item>
                        </Form>
                    </Spin>
                </div>
            </Modal>
        </>
    );
};

export default RegisterModal;
