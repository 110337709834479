import React, { useEffect, useState } from 'react'
import NavigationBar from '../../components/navigationBar'
import { CiCircleCheck } from "react-icons/ci";
import calendarIcon from "../../assets/icons/calendarIcon.svg"
import clockIcon from "../../assets/icons/clockIcon.svg"
import locationIcon from "../../assets/icons/locationIcon.svg"
import ticketIcon from "../../assets/icons/ticketIcon.svg"
import { QRCode } from 'antd';
import { Link } from 'react-router-dom';
import logoImg from "../../assets/images/logoImg.png"


const RegistrationSuccess = () => {

    const [userData, setUserData] = useState<any>(null)

    //onlaod
    useEffect(() => {
        const user_data = localStorage.getItem("userData")
        if (user_data) {
            setUserData(JSON.parse(user_data))
        }
    }, [])

    return (
        <>
            <div className="h-screen overflow-hidden">
                <div className="h-full overflow-y-auto">
                    {/* navbar */}
                    <NavigationBar />

                    {/* content */}
                    <div className="content-container">
                        <div className="w-full 2xl:px-[80px] xl:px-10 px-5 pt-[44px] pb-[40px]">
                            {/* success */}
                            <div className="p-[33px] bg-[#F0FFF4] rounded-[4px] border border-[#72B775] flex gap-[28px] items-center">
                                <CiCircleCheck className='h-10 w-10 text-[#66D864]' />
                                <p className='text-[20px] font-[400] text-[#323743]'>Registration Successful</p>
                            </div>

                            <div className="mt-[40px]">
                                <p className='text-[32px] font-[700] text-[#171A1F]'>
                                    3i Summit 2024: Unleashing Africa's FinTech and Digital <br /> Economic Potential
                                </p>
                            </div>

                            {/* details */}
                            <div className="mt-[35px] 2xl:grid xl:grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-2 gap-[30px]">
                                {/* First */}
                                <div className="w-full flex gap-[24px]">
                                    <div className='h-20 w-20 rounded-[4px] bg-[#FEF4EA] flex justify-center items-center'>
                                        <img src={calendarIcon} alt="." />
                                    </div>
                                    <div>
                                        <p className='text-[16px] font-[700] text-[#171A1F] uppercase'>Date and time</p>
                                        <p className='text-[16px] font-[400] text-[#6F7787] mt-[2px]'>Monday, May 13th 2023</p>
                                        <p className='text-[16px] font-[400] text-[#6F7787] mt-[2px]'>08:00 AM</p>
                                    </div>
                                </div>

                                {/* second */}
                                <div className="w-full flex gap-[24px] mt-5 2xl:mt-0 xl:mt-0">
                                    <div className='h-20 w-20 rounded-[4px] bg-[#FEF4EA] flex justify-center items-center'>
                                        <img src={clockIcon} alt="." />
                                    </div>
                                    <div>
                                        <p className='text-[16px] font-[700] text-[#171A1F] uppercase'>Duration</p>
                                        <p className='text-[16px] font-[400] text-[#6F7787] mt-[2px]'>5 Hours</p>
                                    </div>
                                </div>

                                {/* third */}
                                <div className="w-full flex gap-[24px] mt-5 2xl:mt-0 xl:mt-0">
                                    <div className='h-20 w-20 rounded-[4px] bg-[#FEF4EA] flex justify-center items-center'>
                                        <img src={locationIcon} alt="." />
                                    </div>
                                    <div>
                                        <p className='text-[16px] font-[700] text-[#171A1F] uppercase'>Place</p>
                                        <p className='text-[16px] font-[400] text-[#6F7787] mt-[2px]'>Central Park, New</p>
                                        <p className='text-[16px] font-[400] text-[#6F7787] mt-[2px]'>York, NY, United States</p>
                                    </div>
                                </div>
                            </div>

                            <hr className='my-[32px] border-[#DEE1E6]' />

                            <p className='text-[24px] font-[700] text-[#171A1F]'>Your Ticket</p>
                            {/* ticket info */}
                            <div className="mt-[22px] border border-[#F3F4F6] rounded-[4px] p-[24px] bg-[#FAFAFB] 2xl:flex xl:flex gap-[50px] justify-between">
                                {/* left */}
                                <div className="w-full">
                                    <div className="flex gap-[10px] items-center">
                                        <img src={ticketIcon} alt="." />
                                        <p className='text-[16px] font-[700] text-[#424856]'>Ticket 1</p>
                                    </div>

                                    <div className="mt-[24px] 2xl:grid xl:grid lg:grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-2 gap-[50px] w-full">
                                        <div className='w-full'>
                                            <p className='text-[16px] font-[400] text-[#6F7787]'>First name</p>
                                            <p className='text-[16px] font-[400] text-[#171A1F] mt-[5px]'>{userData?.first_name || "Amanda"}</p>
                                        </div>
                                        <div className='w-full mt-5 2xl:mt-0 xl:mt-0'>
                                            <p className='text-[16px] font-[400] text-[#6F7787]'>Last name</p>
                                            <p className='text-[16px] font-[400] text-[#171A1F] mt-[5px]'>{userData?.last_name || "Smith"}</p>
                                        </div>
                                        <div className='w-full mt-5 2xl:mt-0 xl:mt-0 lg:mt-0'>
                                            <p className='text-[16px] font-[400] text-[#6F7787]'>Company</p>
                                            <p className='text-[16px] font-[400] text-[#171A1F] mt-[5px]'>{userData?.company || "MingoBlox"}</p>
                                        </div>
                                        <div className='w-full mt-5 2xl:mt-0 xl:mt-0 lg:mt-0'>
                                            <p className='text-[16px] font-[400] text-[#6F7787]'>Email</p>
                                            <p className='text-[16px] font-[400] text-[#171A1F] mt-[5px]'>{userData?.email || "Software Engineer"}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* right */}
                                <div className='flex mt-5 2xl:mt-0 xl:mt-0 lg:mt-0'>
                                    <div className='h-[145px] w-[145px] bg-white flex flex-col justify-center items-center border-r-[7px] border-r-[#FAFAFB] border-dotted'>
                                        <p className='text-[16px] font-[400] text-defaultOrange'>Ticket Code</p>
                                        <p className='text-[16px] font-[700] text-defaultOrange mt-[5px]'>C93E5</p>
                                    </div>

                                    <div className='h-[145px] w-[145px] bg-white flex justify-center items-center'>
                                        <QRCode className='w-full h-full' iconSize={24} icon={logoImg} value='https://www.mingoblox.com' bordered={false} size={120} />
                                    </div>
                                </div>
                            </div>

                            {/* buttons */}
                            <div className="mt-[44px] 2xl:flex xl:flex lg:flex justify-between w-full">
                                <Link to="/" className='h-[44px] px-[23px] bg-[#FEF4EA] flex justify-center items-center rounded-[4px]'>
                                    <p className='text-[16px] font-[400] text-defaultOrange'>Go to home</p>
                                </Link>

                                <Link to="/view-ticket" className='h-[44px] px-[23px] bg-defaultOrange flex justify-center items-center rounded-[4px] mt-5 2xl:mt-0 xl:mt-0 lg:mt-0'>
                                    <p className='text-[16px] font-[400] text-white'>View / Download E-ticket</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegistrationSuccess
