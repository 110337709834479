import React, { useEffect, useState } from 'react'
import NavigationBar from '../../components/navigationBar'
import policyIcon from "../../assets/icons/policyIcoBlack.svg"
import { Input, message } from 'antd'
import DefaultButton from '../../components/defaultButton'
import { useNavigate } from 'react-router-dom'

const PersonalDetailsPage = () => {

    const navigate = useNavigate()
    const [isBusy, setIsBusy] = useState(false)
    const [theEmail, setTheEmail] = useState("")
    const [fName, setFName] = useState("")
    const [lName, setLName] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [profession, setProfession] = useState("")

    //onload
    useEffect(() => {
        const storedEmail = localStorage.getItem("userEmail")
        if (storedEmail) {
            setTheEmail(storedEmail)
        }

        const user_data = localStorage.getItem("userData")
        if (user_data) {
            const parsedData = JSON.parse(user_data)
            setFName(parsedData?.first_name)
            setLName(parsedData?.last_name)
            setCompanyName(parsedData?.company)
            setProfession(parsedData?.profession)
            setTheEmail(parsedData?.email)
        }
    }, [])

    //handleRegister
    const handleRegister = () => {
        if (!theEmail) {
            message.warning("Please enter your email")
        } else if (!fName) {
            message.warning("First name cannot be empty")
        } else if (!lName) {
            message.warning("Last name cannot be empty")
        } else if (!profession) {
            message.warning("Please enter your profession")
        } else if (!companyName) {
            message.warning("Enter your company name")
        } else {
            const userData = {
                first_name: fName,
                last_name: lName,
                email: theEmail,
                profession: profession,
                company: companyName
            }
            setIsBusy(true)
            setTimeout(() => {
                localStorage.setItem("userData", JSON.stringify(userData))
                setIsBusy(false)
                navigate("/questions")
            }, 2000)
        }
    }

    return (
        <>
            <div className="h-screen overflow-hidden">
                <div className="h-full overflow-y-auto">
                    {/* navbar */}
                    <NavigationBar />

                    {/* content */}
                    <div className="content-container">
                        <div className="w-full 2xl:grid 2xl:gap-[127px] xl:grid xl:gap-[127px] grid-cols-2 pb-10">
                            {/* left */}
                            <div className="w-full">
                                <div className="bg-bannerBg bg-cover bg-no-repeat h-[310px]"></div>

                                <div className="mt-[46px] 2xl:px-[80px] xl:px-10 px-5">
                                    <div className="flex gap-[16px] items-center">
                                        <img className='text-black' src={policyIcon} alt="." />
                                        <p className='text-[32px] font-[700]'>Policy Pass</p>
                                    </div>

                                    <div className="mt-[16px]">
                                        <p className='text-[16px] font-[400]'>
                                            For industry professionals, investors, entrepreneurs, and private sector representatives looking to engage in networking and attending panel discussions
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* right */}
                            <div className="w-full flex flex-col justify-center px-5 mt-10 2xl:mt-0 xl:mt-0 pt-[50px]">
                                <p className='text-[40px] font-[700]'>Almost there</p>

                                <p className='mt-[5px] text-[16px] font-[400] text-[#57585D]'>
                                    Join us to try the different experiment for the conference
                                </p>

                                <div className="mt-[60px] 2xl:w-[416px] xl:w-[416px] lg:w-[416px] w-full">
                                    <div className='w-full'>
                                        <Input type='email' className='w-full h-[54px] border-[0.5px] border-[#CFCFD1]' value={theEmail} onChange={(e) => setTheEmail(e.target.value)} placeholder='Email' />

                                        <Input type='text' className='w-full h-[54px] border-[0.5px] border-[#CFCFD1] mt-[16px]' value={fName} onChange={(e) => setFName(e.target.value)} placeholder='First Name' />

                                        <Input type='text' className='w-full h-[54px] border-[0.5px] border-[#CFCFD1] mt-[16px]' value={lName} onChange={(e) => setLName(e.target.value)} placeholder='Last Name' />

                                        <Input type='text' className='w-full h-[54px] border-[0.5px] border-[#CFCFD1] mt-[16px]' value={profession} onChange={(e) => setProfession(e.target.value)} placeholder='Profession' />

                                        <Input type='text' className='w-full h-[54px] border-[0.5px] border-[#CFCFD1] mt-[16px]' value={companyName} onChange={(e) => setCompanyName(e.target.value)} placeholder='Company Name' />

                                        <div className="mt-[40px] w-full">
                                            <DefaultButton btnName="Register" onClick={handleRegister} isBusy={isBusy} disabled={isBusy} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PersonalDetailsPage
