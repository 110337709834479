import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import BaseService from '../../helpers/baseServices';
import { global_variables } from '../../helpers/globalVariables';
import { CircleLoader } from 'react-spinners';
import QRCodeGenerator from '../../components/shared/qrCodeGenerator';
import { useGeneralContext } from '../../context/generalContext';


const ViewTicket = () => {

    const { id } = useParams()
    const { event_data } = useGeneralContext()
    const [isFetching, setIsFetching] = useState(true)
    const [ticketInfo, setTicketInfo] = useState<any>(null)

    //onlaod
    useEffect(() => {
        if (id) {
            const getEventTicket = async () => {
                setIsFetching(true)

                try {
                    const response = await BaseService.get_api(`${global_variables.get_one_ticket}/${id}`)
                    console.log("getTicketRes:", response?.data)
                    setTicketInfo(response?.data?.payload)
                    setIsFetching(false)
                    downloadPDF()
                } catch (error) {
                    console.log("getTicketErr:", error)
                }
            }

            getEventTicket()
        }
    }, [id])


    const downloadPDF = () => {
        setTimeout(() => {
            const pdfElement = document.getElementById('pdf');
            if (!pdfElement) return; // Exit if element is not found

            const pdfWidth = 210; // A4 width in mm
            const pdfHeight = 320; // A4 height in mm
            const aspectRatio = pdfWidth / pdfHeight;

            // Calculate canvas height based on aspect ratio
            const canvasHeight = pdfElement.offsetWidth / aspectRatio;

            html2canvas(pdfElement, { height: canvasHeight }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight); // Use A4 dimensions
                pdf.save(`${event_data?.name}.pdf`);
                localStorage.clear(); // Clear localStorage after PDF generation
            }).catch((error) => {
                console.error('Error generating PDF:', error);
            });

            localStorage.clear()
        }, 1000)
    };


    return (
        <>
            {
                isFetching ?
                    <div className="h-screen w-full overflow-y-hidden">
                        <div className="flex flex-col justify-center items-center h-full">
                            <CircleLoader size={100} color='#F7426F' />
                            <p className='mt-5 font-[400] text-gray-500'>Preparing your ticket ...</p>
                        </div>
                    </div>
                    :
                    <div className="h-screen w-full">
                        <div className="w-full">
                            {/* content */}
                            <div className="content-container">
                                <div className="w-full h-full flex items-center justify-center 2xl:px-[80px] xl:px-10 px-5 mt-10">
                                    <div className='2xl:w-[458px] xl:w-[458px] lg:w-[458px] w-full'>
                                        {/* pdf download */}
                                        <div className="w-full border-x border-x-[#F3F4F6] rounded-[4px] bg-white" id='pdf'>
                                            <div className='h-[83px] bg-bannerBg bg-cover bg-no-repeat'></div>
                                            <div className='mt-[21px] flex flex-col items-center'>
                                                <p className='text-[28px] font-[700] text-[#171A1F] text-center'>{ticketInfo?.firstName} {ticketInfo?.lastName}</p>

                                                <p className='text-[22px] font-[400] text-[#171A1F] mt-[7px] text-center'>{ticketInfo?.jobFucntionData?.name}</p>
                                                <p className='text-[14px] font-[400] text-defaultOrange mt-[7px] text-center'>{ticketInfo?.companyName}</p>

                                                <div className='mt-[31px] w-full'>
                                                    <div className="flex justify-center">
                                                        <QRCodeGenerator id={ticketInfo?._id} />
                                                    </div>

                                                    <div className="mt-[25px] text-center">
                                                        <p className='text-[22px] font-[400] text-[#171A1F]'>Ticket Code</p>
                                                        <p className='text-[14px] font-[700] mt-[4px]'>{ticketInfo?._id}</p>
                                                    </div>

                                                    <div className="mt-[31px] h-[124px] w-full text-white bg-defaultOrange flex items-center justify-center">
                                                        <p className='2xl:text-[56px] xl:text-[56px] lg:text-[42px] text-[28px] font-[700]'>{ticketInfo?.roleData?.name || "Regular"}</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-[24px] mb-5 flex justify-center">
                                            <Link to={`/${ticketInfo?.eventId}`} className='w-[156px] h-[44px] flex justify-center items-center rounded-[4px] bg-[#FEF4EA] text-defaultOrange'>
                                                <p className='text-[16px] font-[400]'>Go to home</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default ViewTicket
