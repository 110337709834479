import axios from "axios";
import { BASE_URL } from "./constants";

const config: any = {
  headers: {
    "Content-Type": "application/json",
  },
};

const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJ5ZWpSMERFQXUxckR1anBXbWtIRDNvelAiLCJpYXQiOjE3MjEzODQzODh9.NyxAjvg_1bdiDfV3xfgj3chlgAm-E3oYJH4a6RLrOqQ";

//Axios Post
const post_api = (route: string, data: any) => {
  config.headers["x-access-token"] = token;
  return axios.post(BASE_URL + route, data, config);
};

//Axios Get
const get_api = (route: string) => {
  config.headers["x-access-token"] = token;
  return axios.get(BASE_URL + route, config);
};

//Axios Put
const put_api = (route: string, data: any) => {
  config.headers["x-access-token"] = token;
  return axios.put(BASE_URL + route, data, config);
};

//Axios Delete
const delete_api = (route: string, data: any) => {
  var config: any = {
    method: "delete",
    url: BASE_URL + route,
    headers: {
      "x-access-token": token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  config.headers["x-access-token"] = token;
  return axios(config);
};

// file upload
const file_upload = (name: string, file: any, mimeType: any) => {
  const data = new FormData();
  data.append("file", file, "[PROXY]");

  let fileConfig: any = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${BASE_URL}/robocentre/file/upload?imagename=${name}&storage=test&mimeType=${mimeType}`,
    headers: {
      "x-access-token": token,
    },
    data: data,
  };
  try {
    const response = axios.request(fileConfig);
    return response;
  } catch (error) {
    return error;
  }
};

const BaseService = {
  post_api,
  get_api,
  put_api,
  delete_api,
  file_upload,
};

export default BaseService;
