import React from 'react'
import logoImg from "../assets/images/logo.png"
import { Link } from 'react-router-dom'

const NavigationBar = () => {
    return (
        <>
            <div className="w-full flex justify-between items-center bg-defaultDark py-[24px] desktop:px-[80px] laptop:px-10 px-5 text-white">
                <Link to="/" className='w-[109px]'>
                    <img src={logoImg} alt="." />
                </Link>

                <div className=""></div>
                <div className=""></div>
            </div>
        </>
    )
}

export default NavigationBar
