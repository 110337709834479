// src/components/CountdownTimer.tsx

import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds }: any) => {
    return (
        <div className='flex justify-center items-center'>
            <div className='2xl:px-[53px] xl:px-[53px] lg:px-[53px] md:px-[33px] px-[20px] py-[13px] bg-gray-100 rounded flex flex-col mr-[20px]'>
                <span className='2xl:text-[64px] xl:text-[64px] lg:text-[64px] md:text-[44px] text-[18px] font-[700] text-[#171A1F] text-center'>{zeroPad(days)}</span>
                <span className='text-[#BDC1CA] text-center 2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] text-[12px]'>DAYS</span>
            </div>

            <div className='font-bold text-[#666] 2xl:text-[32px] xl:text-[32px] lg:text-[32px] md:text-[24px] text-[18px]'>:</div>

            <div className='2xl:px-[53px] xl:px-[53px] lg:px-[53px] md:px-[33px] px-[20px] py-[13px] bg-gray-100 rounded flex flex-col mx-[20px]'>
                <span className='2xl:text-[64px] xl:text-[64px] lg:text-[64px] md:text-[44px] text-[18px] font-[700] text-[#171A1F] text-center'>{zeroPad(hours)}</span>
                <span className='text-[#BDC1CA] text-center 2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] text-[12px]'>HOURS</span>
            </div>

            <div className='font-bold text-[#666] 2xl:text-[32px] xl:text-[32px] lg:text-[32px] md:text-[24px] text-[18px]'>:</div>

            <div className='2xl:px-[53px] xl:px-[53px] lg:px-[53px] md:px-[33px] px-[20px] py-[13px] bg-gray-100 rounded flex flex-col mx-[20px]'>
                <span className='2xl:text-[64px] xl:text-[64px] lg:text-[64px] md:text-[44px] text-[18px] font-[700] text-[#171A1F] text-center'>{zeroPad(minutes)}</span>
                <span className='text-[#BDC1CA] text-center 2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] text-[12px]'>MINUTES</span>
            </div>

            <div className='font-bold text-[#666] 2xl:text-[32px] xl:text-[32px] lg:text-[32px] md:text-[24px] text-[18px]'>:</div>

            <div className='2xl:px-[53px] xl:px-[53px] lg:px-[53px] md:px-[33px] px-[20px] py-[13px] bg-gray-100 rounded flex flex-col ml-[20px]'>
                <span className='2xl:text-[64px] xl:text-[64px] lg:text-[64px] md:text-[44px] text-[18px] font-[700] text-[#171A1F] text-center'>{zeroPad(seconds)}</span>
                <span className='text-[#BDC1CA] text-center 2xl:text-[16px] xl:text-[16px] lg:text-[16px] md:text-[16px] text-[12px]'>SECONDS</span>
            </div>
        </div>
    );
};

const CountdownTimer = ({ date }: { date: string }) => {
    return <Countdown date={date} renderer={renderer} />;
};



export default CountdownTimer;
