import moment from "moment";

export function FormatDateRange(start: any, end: any) {
  const startDate = moment(start);
  const endDate = moment(end);

  return `${startDate.format("DD")}-${endDate.format("DD")} ${endDate.format(
    "MMM, YYYY"
  )}`;
}
