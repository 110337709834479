import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';

interface btnProps {
    onClick?: () => void
    btnName?: string | any
    disabled?: boolean
    isBusy?: boolean
}

const DefaultButton = ({ onClick, btnName, disabled, isBusy }: btnProps) => {
    return (
        <>
            <button className='h-[56px] w-full rounded-[8px] bg-defaultOrange text-white disabled:cursor-not-allowed flex justify-center items-center' onClick={onClick} disabled={disabled}>
                {
                    isBusy ?
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} />
                        :
                        <p className='text-[20px] font-[500]'>{btnName}</p>
                }
            </button>
        </>
    )
}

export default DefaultButton
