import React, { useState } from 'react'
import NavigationBar from '../../components/navigationBar'
import { message, Radio, Space } from 'antd';
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

const QuestionsPage = () => {

    const navigate = useNavigate()
    const [currTab, setCurrTab] = useState(0)
    const [firstVal, setFirstVal] = useState("")
    const [secondVal, setSecondVal] = useState("")
    const [thirdVal, setThirdVal] = useState("")

    //handleFirstCont
    const handleFirstCont = () => {
        if (!firstVal) {
            message.warning("Please select an option")
        } else {
            setCurrTab(1)
        }
    }

    //handleSecondCont
    const handleSecondCont = () => {
        if (!secondVal) {
            message.warning("Please select an option")
        } else {
            setCurrTab(2)
        }
    }

    //handleBackOne
    const handleBackOne = () => {
        setCurrTab(0)
    }

    //handleBackTwo
    const handleBackTwo = () => {
        setCurrTab(1)
    }

    //handleFinish
    const handleFinish = () => {
        if (!thirdVal) {
            message.warning("Please select an option")
        } else {
            navigate("/successful")
        }
    }


    return (
        <>
            <div className="h-screen overflow-hidden">
                <div className="h-full overflow-y-auto">
                    {/* navbar */}
                    <NavigationBar />

                    {/* content */}
                    <div className="content-container">
                        <div className="w-full 2xl:px-[80px] xl:px-10 px-5 flex justify-center mt-[55px] h-full">
                            <div className="2xl:w-[614px] xl:w-[614px] lg:w-[614px] w-full">
                                {/* section 1 */}
                                {
                                    currTab === 0 ?
                                        <>
                                            <div className="w-full">
                                                <div className='flex gap-[23px] justify-between items-center w-full'>
                                                    <div className='h-[4px] w-full rounded-[4px] bg-defaultOrange'></div>
                                                    <div className='h-[4px] w-full rounded-[4px] bg-[#DEE1E6]'></div>
                                                    <div className='h-[4px] w-full rounded-[4px] bg-[#DEE1E6]'></div>
                                                </div>

                                                <p className='mt-[34px] text-[14px] font-[400] text-[#6F7787]'>1 of 3</p>

                                                <p className='mt-[30px] font-[400] text-[32px] text-[#171A1F]'>A. What is your main job function?</p>

                                                <div className="w-full mt-[30px]">
                                                    <Radio.Group onChange={(e) => setFirstVal(e.target.value)}>
                                                        <Space className='gap-[18px]' direction="vertical">
                                                            <Radio value="Production Manager">Production Manager</Radio>
                                                            <Radio value="Production Technician">Production Technician</Radio>
                                                            <Radio value="Owner/CEO/MD">Owner/CEO/MD</Radio>
                                                            <Radio value="General Management">General Management</Radio>
                                                            <Radio value="Chief Operating Officer">Chief Operating Officer</Radio>
                                                        </Space>
                                                    </Radio.Group>
                                                </div>

                                                <div className="mt-[62px] 2xl:flex xl:flex lg:flex justify-between">
                                                    <button className='h-[44px] px-[23px] border border-black text-black gap-1 flex justify-center items-center rounded-[4px] mt-5 2xl:mt-0 xl:mt-0 lg:mt-0' onClick={() => navigate("/personal-detail")}>
                                                        <IoIosArrowRoundBack className='h-6 w-6' />
                                                        <p className='text-[16px] font-[400]'>Back</p>
                                                    </button>

                                                    <button className='h-[44px] px-[23px] bg-defaultOrange text-white gap-1 flex justify-center items-center rounded-[4px] mt-5 2xl:mt-0 xl:mt-0 lg:mt-0' onClick={handleFirstCont}>
                                                        <p className='text-[16px] font-[400]'>Continue</p>
                                                        <IoIosArrowRoundForward className='h-6 w-6' />
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                        : currTab === 1 ?
                                            <>
                                                <div className="w-full">
                                                    <div className='flex gap-[23px] justify-between items-center w-full'>
                                                        <div className='h-[4px] w-full rounded-[4px] bg-defaultOrange'></div>
                                                        <div className='h-[4px] w-full rounded-[4px] bg-defaultOrange'></div>
                                                        <div className='h-[4px] w-full rounded-[4px] bg-[#DEE1E6]'></div>
                                                    </div>

                                                    <p className='mt-[34px] text-[14px] font-[400] text-[#6F7787]'>2 of 3</p>

                                                    <p className='mt-[30px] font-[400] text-[32px] text-[#171A1F]'>B. Please indicate any other areas in which your business operates?</p>

                                                    <div className="w-full mt-[30px]">
                                                        <Radio.Group onChange={(e) => setSecondVal(e.target.value)}>
                                                            <Space className='gap-[18px]' direction="vertical">
                                                                <Radio value="Beverage">Beverage</Radio>
                                                                <Radio value="Printing">Printing</Radio>
                                                                <Radio value="Industrial Goods">Industrial Goods</Radio>
                                                                <Radio value="Administrative Service">Administrative Service</Radio>
                                                            </Space>
                                                        </Radio.Group>
                                                    </div>

                                                    <div className="mt-[62px] 2xl:flex xl:flex lg:flex justify-between">
                                                        <button className='h-[44px] px-[23px] border border-black text-black gap-1 flex justify-center items-center rounded-[4px] mt-5 2xl:mt-0 xl:mt-0 lg:mt-0' onClick={handleBackOne}>
                                                            <IoIosArrowRoundBack className='h-6 w-6' />
                                                            <p className='text-[16px] font-[400]'>Back</p>
                                                        </button>

                                                        <button className='h-[44px] px-[23px] bg-defaultOrange text-white gap-1 flex justify-center items-center rounded-[4px] mt-5 2xl:mt-0 xl:mt-0 lg:mt-0' onClick={handleSecondCont}>
                                                            <p className='text-[16px] font-[400]'>Continue</p>
                                                            <IoIosArrowRoundForward className='h-6 w-6' />
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                            : currTab === 2 ?
                                                <>
                                                    <div className="w-full">
                                                        <div className='flex gap-[23px] justify-between items-center w-full'>
                                                            <div className='h-[4px] w-full rounded-[4px] bg-defaultOrange'></div>
                                                            <div className='h-[4px] w-full rounded-[4px] bg-defaultOrange'></div>
                                                            <div className='h-[4px] w-full rounded-[4px] bg-defaultOrange'></div>
                                                        </div>

                                                        <p className='mt-[34px] text-[14px] font-[400] text-[#6F7787]'>3 of 3</p>

                                                        <p className='mt-[30px] font-[400] text-[32px] text-[#171A1F]'>C. Which of the options below prompted you to register?</p>

                                                        <div className="w-full mt-[30px]">
                                                            <Radio.Group onChange={(e) => setThirdVal(e.target.value)}>
                                                                <Space className='gap-[18px]' direction="vertical">
                                                                    <Radio value="Email">Email</Radio>
                                                                    <Radio value="Billboard">Billboard</Radio>
                                                                    <Radio value="Industry publication">Industry publication</Radio>
                                                                    <Radio value="Invitation from exhibitor">Invitation from exhibitor</Radio>
                                                                    <Radio value="Invitation from association">Invitation from association</Radio>
                                                                </Space>
                                                            </Radio.Group>
                                                        </div>

                                                        <div className="mt-[62px] 2xl:flex xl:flex lg:flex justify-between">
                                                            <button className='h-[44px] px-[23px] border border-black text-black gap-1 flex justify-center items-center rounded-[4px] mt-5 2xl:mt-0 xl:mt-0 lg:mt-0' onClick={handleBackTwo}>
                                                                <IoIosArrowRoundBack className='h-6 w-6' />
                                                                <p className='text-[16px] font-[400]'>Back</p>
                                                            </button>

                                                            <button className='h-[44px] px-[23px] bg-defaultOrange text-white gap-1 flex justify-center items-center rounded-[4px] mt-5 2xl:mt-0 xl:mt-0 lg:mt-0' onClick={handleFinish}>
                                                                <p className='text-[16px] font-[400]'>Continue</p>
                                                                <IoIosArrowRoundForward className='h-6 w-6' />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default QuestionsPage
