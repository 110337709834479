import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RegisterPage from "./pages/register/registerPage";
import WelcomePage from "./pages/welcome/welcomePage";
import PersonalDetailsPage from "./pages/personalDetial/personalDetial";
import ViewTicket from "./pages/viewTicket/viewTicket";
import RegistrationSuccess from "./pages/registrationSuccess/registrationSuccess";
import QuestionsPage from "./pages/questions/questionsPage";
import HomePage from "./pages/home/homePage";
import { GeneralContextProvider } from "./context/generalContext";

function App() {
  return (
    <>
      <GeneralContextProvider>
        <Router>
          <Routes>
            <Route path="/*" element={<h1>Page not found!!</h1>} />
            <Route path="/:id" element={<HomePage />} />
            <Route path="/register/:id" element={<RegisterPage />} />
            <Route path="/welcome" element={<WelcomePage />} />
            <Route path="/personal-detail" element={<PersonalDetailsPage />} />
            <Route path="/questions" element={<QuestionsPage />} />
            <Route path="/view-ticket/:id" element={<ViewTicket />} />
            <Route path="/successful" element={<RegistrationSuccess />} />
          </Routes>
        </Router>
      </GeneralContextProvider>
    </>
  );
}

export default App;
