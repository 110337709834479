import React, { useState } from 'react'
import NavigationBar from '../../components/navigationBar'
import policyIcon from "../../assets/icons/policyIcoBlack.svg"
import { Input, message } from 'antd'
import DefaultButton from '../../components/defaultButton'
import { useNavigate } from 'react-router-dom'

const WelcomePage = () => {

    const navigate = useNavigate()
    const [theEmail, setTheEmail] = useState("")
    const [isBusy, setIsBusy] = useState(false)

    //handleContinue
    const handleContinue = () => {
        if (!theEmail) {
            message.warning("Please enter your email")
        } else {
            setIsBusy(true)
            setTimeout(() => {
                setIsBusy(false)
                navigate("/personal-detail")
                localStorage.setItem("userEmail", theEmail)
            }, 2000)
        }
    }

    return (
        <>
            <div className="h-screen overflow-hidden">
                <div className="h-full overflow-y-auto">
                    {/* navbar */}
                    <NavigationBar />

                    {/* content */}
                    <div className="content-container">
                        <div className="w-full 2xl:grid 2xl:gap-[127px] xl:grid xl:gap-[127px] grid-cols-2 pb-10">
                            {/* left */}
                            <div className="w-full">
                                <div className="bg-bannerBg bg-cover bg-no-repeat 2xl:h-[310px] xl:h-[310px] lg:h-[150px] h-[150px]"></div>

                                <div className="mt-[46px] 2xl:px-[80px] xl:px-10 px-5">
                                    <div className="flex gap-[16px] items-center">
                                        <img className='text-black' src={policyIcon} alt="." />
                                        <p className='text-[32px] font-[700]'>Policy Pass</p>
                                    </div>

                                    <div className="mt-[16px]">
                                        <p className='text-[16px] font-[400]'>
                                            For industry professionals, investors, entrepreneurs, and private sector representatives looking to engage in networking and attending panel discussions
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* right */}
                            <div className="w-full flex flex-col justify-center px-5 mt-10 2xl:mt-0 xl:mt-0">
                                <p className='text-[40px] font-[700]'>Welcome</p>

                                <p className='mt-[5px] text-[16px] font-[400] text-[#57585D]'>Lets get you Signed up</p>

                                <div className="mt-[60px] 2xl:w-[416px] xl:w-[416px] lg:w-[416px] w-full">
                                    <div className='w-full'>
                                        <label htmlFor="email" className='text-[14px] font-[400] text-[#57585D]'>Email</label>
                                        <Input type='email' id='email' className='mt-[4px] w-full h-[54px] border-[0.5px] border-[#CFCFD1]' value={theEmail} onChange={(e) => setTheEmail(e.target.value)} placeholder='example@gmail.com' />

                                        <div className="mt-[40px] w-full">
                                            <DefaultButton btnName="Continue" onClick={handleContinue} isBusy={isBusy} disabled={isBusy} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WelcomePage
