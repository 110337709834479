import React, { useEffect, useState } from 'react'
import NavigationBar from '../../components/navigationBar'
import policyIcon from "../../assets/icons/policyIcon.svg"
import industryIcon from "../../assets/icons/industryIcon.svg"
import researchIcon from "../../assets/icons/researchIcon.svg"
import mediaIcon from "../../assets/icons/mediaIcon.svg"
import { useNavigate, useParams } from 'react-router-dom'
import { Skeleton, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import BaseService from '../../helpers/baseServices'
import { global_variables } from '../../helpers/globalVariables'
import RegisterModal from './registerModal'
import CustomStickyNav from '../../components/customStickyNav'
import { CircleLoader } from 'react-spinners'

const RegisterPage = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const tempList = Array.from({ length: 4 })
    const [isLoading, setIsLoading] = useState(false)
    const [activeTab, setActiveTab] = useState<any>(null)
    const [isFetching, setIsFetching] = useState(true)
    const [eventRoles, setEventRoles] = useState([])
    const [openRegisterModal, setOpenRegisterModal] = useState(false)

    //onload
    useEffect(() => {
        if (id) {
            console.log("___eventId:", id)
            const getAllEventRoles = async () => {
                setIsFetching(true)
                try {
                    const response = await BaseService.get_api(`${global_variables.get_event_roles}?filter={"eventId": ${JSON.stringify(id)}}`)
                    console.log("eventRoles:", response?.data)
                    setEventRoles(response?.data?.payload)
                    setIsFetching(false)
                } catch (error) {
                    console.log(error)
                }
            }

            getAllEventRoles()
        }
    }, [])


    const passList = [
        {
            icon: policyIcon,
            title: "Policy Pass",
            description: "Tailored for government officials, policymakers, regulators, multilaterals and think tanks, facilitating their participation in high-level dialogues and policy-focused sessions.",
            index: 0
        },
        {
            icon: industryIcon,
            title: "Industry Pass",
            description: "For industry professionals, investors, entrepreneurs, and private sector representatives looking to engage in networking and attending panel discussions",
            index: 1
        },
        {
            icon: researchIcon,
            title: "Research Pass",
            description: "Designed for non-profits, educators, students, and research scholars, looking to access educational sessions and opportunities for academic exchange.",
            index: 2
        },
        {
            icon: mediaIcon,
            title: "Media Pass",
            description: "Reserved for journalists, bloggers, and media professionals covering the event, providing access to press briefings and exclusive interviews.",
            index: 3
        }
    ]


    //handleSelection
    const handleSelection = (index: number, selected: any) => {
        setIsLoading(true)
        setActiveTab(index)
        setTimeout(() => {
            setIsLoading(false)
            setOpenRegisterModal(true)
        }, 1000)
    }


    return (
        <>
            {
                isFetching ?
                    <div className="h-screen w-full overflow-y-hidden">
                        <div className="flex flex-col justify-center items-center h-full">
                            <CircleLoader size={100} color='#F7426F' />
                            <p className='mt-5 font-[400] text-gray-500'>Fetching event details ...</p>
                        </div>
                    </div>
                    :
                    <div className="h-screen w-full">
                        <div className="w-full">
                            {/* navbar */}
                            <CustomStickyNav />

                            {/* content */}
                            <div className="content-container">
                                <div className="2xl:h-[600px] xl:h-[600px] lg:h-[400px] h-[400px] bg-white pb-20 bg-bannerBg relative bg-no-repeat bg-cover">
                                    <div className='h-full absolute 2xl:mt-[100px] xl:mt-[100px] lg:mt-[30px] mt-[30px]'>
                                        <div className="w-full flex justify-center items-center mt-10">
                                            <div className='text-center'>
                                                <p className='text-[66px] font-[700] leading-[115.3px] text-white'>Register</p>
                                                <p className='text-[16px] font-[400] leading-[34px] text-white'>
                                                    Chose your Pass to register
                                                </p>
                                            </div>
                                        </div>

                                        {/* cards */}
                                        <div className="2xl:px-[300px] xl:px-[150px] lg:px-[100px] md:px-[30px] px-[20px] 2xl:mt-[82px] xl:mt-[82px] lg:mt-[30px] mt-[30px] pb-10">
                                            <div className="w-full 2xl:grid 2xl:grid-cols-3 xl:grid xl:grid-cols-3 lg:grid lg:grid-cols-3 gap-[17px] 2xl:px-[80px] xl:px-10 px-5">

                                                {
                                                    eventRoles.map((item: any, i: number) => (
                                                        <div key={i} className="w-full py-[32px] px-[24px] bg-cardBg text-white flex flex-col justify-center mt-10 lg:mt-0 shadow-lg">
                                                            <img className='2xl:h-[60px] xl:h-[60px] lg:h-[40px] h-[40px] 2xl:w-[60px] xl:w-[60px] lg:w-[40px] w-[40px] flex mr-auto ml-auto' src={policyIcon} alt="." />

                                                            <p className='mt-[16px] 2xl:text-[32px] xl:text-[32px] lg:text-[22px] text-[22px] font-[700] text-center'>{item?.name}</p>

                                                            <div className="2xl:h-[150px] xl:h-[150px] lg:h-[150px] h-auto">
                                                                <p className='mt-[16px] 2xl:text-[14px] xl:text-[14px] lg:text-[12.5px] text-[12px] font-[400] text-center'>
                                                                    {item?.description}
                                                                </p>
                                                            </div>

                                                            <div className="mt-[30px]">
                                                                <button className='h-[56px] w-full rounded-[8px] bg-defaultOrange text-white disabled:cursor-not-allowed flex justify-center items-center' onClick={() => handleSelection(i, item)} disabled={isLoading}>
                                                                    {
                                                                        isLoading && activeTab === i ?
                                                                            <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} />
                                                                            :
                                                                            <p className='2xl:text-[20px] xl:text-[20px] lg:text-[14px] text-[14px] font-[500]'>Get Your Pass</p>
                                                                    }
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

            }

            {
                openRegisterModal && <RegisterModal isOpened={openRegisterModal} handleClose={() => setOpenRegisterModal(false)} />
            }
        </>
    )
}

export default RegisterPage
